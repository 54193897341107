export const replaceAwsUrl = (url: string | undefined | null) => {
  if (!url) return url;
  return url.replace("s3-us-west-2.amazonaws.com/www.guilded.gg", "cdn.gilcdn.com")
}

export const cdn_base = "https://cdn.gilcdn.com";

const images = {
  nothing_here: `${cdn_base}/asset/GenericMessages/nothing-here.png`,
  profile_1: `${cdn_base}/asset/DefaultUserAvatars/profile_1.png`,
  profile_2: `${cdn_base}/asset/DefaultUserAvatars/profile_2.png`,
  profile_3: `${cdn_base}/asset/DefaultUserAvatars/profile_3.png`,
  profile_4: `${cdn_base}/asset/DefaultUserAvatars/profile_4.png`,
  profile_5: `${cdn_base}/asset/DefaultUserAvatars/profile_5.png`,
  gil_sm: `${cdn_base}/asset/Default/Gil-sm.png`,
};
export default images;
